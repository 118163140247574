import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel,
  FormControl, Snackbar, Alert
} from '@mui/material';
import { api } from '../api';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    logo: '',
    description: '',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = () => {
    api.get('/companies')
      .then(response => setCompanies(response.data))
      .catch(error => console.error('Error fetching companies:', error));
  };

  const handleOpen = (company = null) => {
    if (company) {
      setFormData({
        name: company.name,
        logo: company.logo,
        description: company.description,
        status: company.status,
      });
      setEditingId(company.id);
    } else {
      setFormData({ name: '', logo: '', description: '', status: 'active' });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name, logo, description, status } = formData;

    // Basic validation
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!logo) formErrors.logo = 'Logo URL is required';
    if (!description) formErrors.description = 'Description is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/companies/${editingId}`, { name, logo, description, status })
      : api.post('/companies', { name, logo, description, status });

    request
      .then(() => {
        fetchCompanies();
        setFlashMessage({ message: editingId ? 'Company updated successfully!' : 'Company added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving company:', error);
        setFlashMessage({ message: 'Error saving company. Please try again.', severity: 'error' });
      });
  };

  const deleteCompany = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/companies/${id}`)
          .then(() => {
            fetchCompanies();
            Swal.fire({
              title: 'Deleted!',
              text: 'The company has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting company:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the company. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Company List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add New Company
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Logo</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map(company => (
              <TableRow key={company.id}>
                <TableCell>{company.name}</TableCell>
                <TableCell><img src={company.logo} alt={company.name} width="50" /></TableCell>
                <TableCell>{company.description}</TableCell>
                <TableCell>{company.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(company)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteCompany(company.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Company' : 'Add Company'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Logo URL"
            name="logo"
            variant="outlined"
            value={formData.logo}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.logo}
            helperText={errors.logo}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Description"
            name="description"
            variant="outlined"
            value={formData.description}
            onChange={handleChange}
            required
            fullWidth
            multiline
            rows={4}
            margin="normal"
            error={!!errors.description}
            helperText={errors.description}
            style={{ marginBottom: '16px' }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
              required
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert
          onClose={() => setFlashMessage({ message: '', severity: '' })}
          severity={flashMessage.severity}
          sx={{ width: '100%' }}
          style={{
            backgroundColor: flashMessage.severity === 'error' ? '#f8d7da' : '#d4edda',
            color: flashMessage.severity === 'error' ? '#721c24' : '#155724'
          }}
        >
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CompanyList;
