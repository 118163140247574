import React from "react";

const MarqueeText = ({ 
  text = "Default scrolling text...", 
  linkText = "", 
  bgColor = "#f8d7da", 
  textColor = "#721c24", 
  speed = "20s",
  onClick 
}) => {
  const marqueeStyle = {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    backgroundColor: bgColor,
    padding: "10px",
    borderRadius: "5px",
    fontWeight: "bold",
    color: textColor,
    cursor: "pointer",
  };

  const textStyle = {
    display: "inline-block",
    whiteSpace: "nowrap",
    paddingLeft: "100%",
    animation: `marquee ${speed} linear infinite`,
  };

  return (
    <div style={marqueeStyle}>
      <span style={textStyle}>
        🚀 {text}{" "}
        <span 
          onClick={onClick} 
          style={{ color: "#007bff", fontWeight: "bold", cursor: "pointer" }}
        >
          {linkText}
        </span>
      </span>
      <style>
        {`
          @keyframes marquee {
            from { transform: translateX(0%); }
            to { transform: translateX(-100%); }
          }
        `}
      </style>
    </div>
  );
};

export default MarqueeText;