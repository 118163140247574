import React, { useEffect, useState } from "react";
import { ThemeProvider, Tooltip, createTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { BsGear, BsPower, BsBell } from "react-icons/bs";
import Loading from "../frontend/register-form/Loading";
import { displayNotification } from "../../helpers";
import { setSidebarState, setUserType } from "../../redux/generalSlice";
import { setLoginState, resetLoginState } from "../../redux/loginSlice";
import { setSignupState, resetSignupState } from "../../redux/signupSlice";
import { API_URL, BASE_URL, IMAGE_URL } from "../../constants";
import { setAuthToken } from '../api';

// Define the custom theme for MUI
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#ff4815",
      contrastText: "white",
    },
  },
});

const BackendLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.userToken);
  // const[userRole, setuserRole ]= useSelector((state) => state.userRole);
  const [userRole, setUserRole] = useState('');

  // const user = useSelector((state) => state.user);
  const [user, setUser] = useState({});
  const currentPage = useSelector((state) => state.general.currentPage);
  const sidebarOpened = useSelector((state) => state.general.sidebarOpened);

  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [option, setOption] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [submitbtn, setSubmit] = useState(false);
  const [badgeNo, setBadgeNo] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedMenus, setExpandedMenus] = useState({});
  const [menuItems, setMenuItems] = useState([]);

  // Define the menu items structure
  const adminMenu = [
    { id: 'admin-dashboard', title: 'Admin Dashboard', icon: 'fas fa-home fa-2x', link: '/dashboard' },
    {
      id: 'vehicle-management',
      title: 'Vehicle Management',
      icon: 'fa-solid fa-car',
      subMenu: [
        { id: 'graph', title: 'Companies', link: '/companies', icon: 'fa-solid fa-chart-line' },
        { id: 'chart', title: 'Models', link: '/models', icon: 'fa-solid fa-chart-bar' },
        { id: 'table', title: 'Vehicles', link: '/vehicles', icon: 'fa-solid fa-table' },
      ],
    },
    {
      id: 'user-management',
      title: 'User Management',
      icon: 'fa-solid fa-user',
      subMenu: [
  
        { id: 'users', title: 'Users X',  link: '/users', icon: '	fa fa-drivers-license' },
        { id: 'drivers', title: 'Drivers',  link: '/drivers', icon: '	fa fa-drivers-license' },
        { id: 'family-members', title: 'Family Members', link: '/family-members', icon: 'fas fa-users' },

      ],
    },
    {
      id: 'address-management',
      title: 'Address Management',
      icon: 'fas fa-globe',
      subMenu: [
  
        { id: 'countries', title: 'Countries',  link: '/countries', icon: 'fas fa-globe-asia' },
        { id: 'provinces', title: 'Provinces',  link: '/provinces', icon: 'far fa-map' },
        { id: 'cities', title: 'Cities',  link: '/cities', icon: 'fas fa-map' },
        { id: 'towns', title: 'Towns',  link: '/towns', icon: 'fas fa-map-marked-alt' },
        { id: 'addresses', title: 'Addresses X',  link: '/addresses', icon: 'fas fa-map-pin' },

      ],
    },

    {
      id: 'prices-management',
      title: 'Prices Management',
      icon: 'fas fa-money-check-alt',
      subMenu: [
  
        { id: 'cl-system-pricing', title: 'CL System Pricing', icon: 'fas fa-comments-dollar', link: '/cl_pricing' },
        { id: 'current-pricing', title: 'Current Pricing', icon: 'fas fa-handshake', link: '/current_prices' },

      ],
    },


    
    { id: 'profile', title: 'Profile', icon: 'fas fa-solid fa-user fa-2x', link: '/profile' },
    { id: 'termscondition', title: 'Terms and Conditions', icon: 'fa-regular fa-file-lines', link: '/termscondition' },
    { id: 'notification', title: 'Notifications', icon: 'fa-solid fa-bell', link: '/notification' },
    { id: 'contactus', title: 'Contact Us', icon: 'fa-solid fa-address-card', link: '/contactus' },
    { id: 'whatsapp', title: 'WhatsApp', icon: 'fa-solid fa-phone', link: 'https://wa.me/923335069216' },
  ];

  const userMenu = [
    { id: 'dashboard', title: 'User Dashboard', icon: 'fas fa-home fa-2x', link: '/dashboard' },
    {
      id: 'finance-management',
      title: 'Finance Management',
      icon: 'fa-solid fa-money-bill',
      subMenu: [
        { id: 'graph', title: 'Bank Account', link: '/bank-accounts', icon: 'fa-solid fa-building-columns' },
        { id: 'chart', title: 'Transactions', link: '/transactions', icon: 'fa fa-credit-card' },
        { id: 'table', title: 'Invoices', link: '/vehicles', icon: 'fa-solid fa-car' },
      ],
    },
    {
      id: 'car-offering',
      title: 'Car Offering',
      icon: 'fas fa-car-side',
      subMenu: [
        { id: 'table', title: 'Car Details', link: '/vehicles', icon: 'fa-solid fa-car' },
        { id: 'table', title: 'Driving Details', link: '/drivers', icon: '	fa fa-drivers-license' },

      ],
    },
    { id: 'family-members', title: 'Family Members', icon: 'fas fa-users', link: '/family-members' },
    {
      id: 'recommendations',
      title: 'Schedules & Recommendations',
      icon: 'fas fa-calendar-alt',
      subMenu: [
        { id: 'schedules', title: 'Schedules', link: '/schedules', icon: 'far fa-calendar-check' },
        { id: 'recommendations', title: 'Recommendations', link: '/recommendations', icon: 'fas fa-hourglass-start' },

      ],
    },

    { id: 'profile', title: 'Profile', icon: 'fas fa-solid fa-user fa-2x', link: '/profile' },
    { id: 'termscondition', title: 'Terms and Conditions', icon: 'fa-regular fa-file-lines', link: '/termscondition' },
    { id: 'notification', title: 'Notifications', icon: 'fa-solid fa-bell', link: '/notification' },
    { id: 'contactus', title: 'Contact Us', icon: 'fa-solid fa-address-card', link: '/contactus' },
    { id: 'whatsapp', title: 'WhatsApp', icon: 'fa-solid fa-phone', link: 'https://wa.me/923335069216' },
  ];

  // Toggle menu item expansion
  const toggleMenu = (menuId) => {
    setExpandedMenus((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  // Render menu items recursively
  const renderMenuItems = (items) => {
    return items.map((item) => (
      <div key={item.id} className="menu-item border-custom">
        {item.subMenu ? (
          <div
            className={`menu-link ${expandedMenus[item.id] ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              toggleMenu(item.id);
            }}
            style={{ borderRadius: '0%', margin: '0%', paddingLeft: '20px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <span className="menu-icon font-custom" style={{ marginRight: '10px' }}>
              <i className={item.icon}></i>
            </span>
            <span className="menu-title font-custom mt-1">
              {item.title}
            </span>
            <span className="menu-arrow" style={{ marginLeft: 'auto' }}>
              <i className={`fa-solid ${expandedMenus[item.id] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
            </span>
          </div>
        ) : (
          <Link
            to={item.link}
            className="menu-link"
            style={{ borderRadius: '0%', margin: '0%', paddingLeft: '20px', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <span className="menu-icon font-custom" style={{ marginRight: '10px' }}>
              <i className={item.icon}></i>
            </span>
            <span className="menu-title font-custom mt-1">
              {item.title}
            </span>
          </Link>
        )}
        {item.subMenu && expandedMenus[item.id] && (
          <div className="submenu" style={{ paddingLeft: '40px' }}>
            {renderMenuItems(item.subMenu)}
          </div>
        )}
      </div>
    ));
  };

  // Get the current date in a formatted string
  const getFormattedDate = () => {
    const currentDate = new Date();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${daysOfWeek[currentDate.getDay()]}, ${monthsOfYear[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
  };

  const formattedDate = getFormattedDate();

  const route = () => {
    setSubmit(true);
    if (!submitbtn) {
      navigate("/commuter-profile");
    }
  };

  const logout = () => {
    Swal.fire({
      position: 'top',
      title: 'Are you sure?',
      text: "You are about to logout from CommutersLink",
      showCancelButton: true,
      cancelButtonColor: 'swal-custom',
      confirmButtonText: 'Logout',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'swal-custom',
        cancelButton: 'swal-custom',
        htmlContainer: 'text-center',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetLoginState());
        dispatch(resetSignupState());
        localStorage.clear();
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {  
    // Retrieve token and user from local storage
    const userToken = localStorage.getItem('userToken');
    const user = JSON.parse(localStorage.getItem('user'));

    setUser(user);

    console.log('User object:', user); // Debugging statement to check the structure
    console.log('User name:', user?.name); // Accessing user.name safely

    // const user = jsonUser ? JSON.parse(jsonUser) : {};

    const userRole = localStorage.getItem('userRole' ) ; // || "user"


    setUserRole(userRole);

    const newMenuItems = userRole === "76242" ? adminMenu : userMenu;
    setMenuItems(newMenuItems);

    if (userToken) {
      // Dispatch action to set login state
      dispatch(setLoginState({ userToken, user, userRole }));



      // Optionally, you can set the auth token here if needed
      setAuthToken(userToken);
    } else {
      // If no token, navigate to login or handle unauthorized access
      navigate('/login');
    }
  }, [dispatch, navigate]);


  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.classList.remove("w-100");
      rootElement.classList.add("d-flex");
      rootElement.classList.add("flex-grow-1");
    }
    window.KTToggle.init();
    window.KTScroll.init();
    getProfileData();
  }, []);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const appBody = document.getElementById("kt_app_body");
    if (appBody) {
      appBody.dataset.ktAppSidebarMinimize = !sidebarOpened ? "on" : "";
    }
  }, [sidebarOpened]);

  const getProfileData = async () => {
    try {
      const response = await fetch(`${API_URL}/api/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const jsonresponse = await response.json();
      if (jsonresponse) {
        setName(jsonresponse[0].name);
        dispatch(setUserType(jsonresponse[0].type));
        setImage(jsonresponse[0].contact.commuter_image);
        setOption(jsonresponse[0].userlist.vehicle_option);
        setUserId(jsonresponse[0].contact.user_id);
      } else {
        setName("");
        setImage("");
      }

      if (jsonresponse.statusCode === 500) {
        displayNotification("error", `${jsonresponse.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getNotifications = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/show-notifications/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const jsonresponse = await response.json();
      if (jsonresponse) {
        setBadgeNo(jsonresponse.data[0].badge_no);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const clearNotification = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/store/${userId}/null`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const jsonresponse = await response.json();
      if (jsonresponse.status_code === 200) {
        getNotifications();
      }
    } catch (error) {
      // Handle the error here if needed
    }
  };





  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center mx-auto my-auto">
          <Loading />
        </div>
      ) : (
        <div className="container-fluid font-custom bg-white">
          <ThemeProvider theme={customTheme}>
            <link rel="stylesheet" href={`/assets/css/backend.css`} />
            <link rel="stylesheet" href={"/assets/frontend.css"} />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700" />

            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
              <div className="app-page flex-column flex-column-fluid bg-white" id="kt_app_page">
                
                {/* Header */}
                <header className="app-header">
                  <div className="w-100 d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
                    
                    {/* Mobile Sidebar Toggle */}
                    <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
                      <button
                        className="btn btn-icon btn-active-color-primary w-35px h-35px"
                        id="kt_app_sidebar_mobile_toggle"
                        onClick={() => dispatch(setSidebarState(!sidebarOpened))}
                      >
                        <span className="svg-icon svg-icon-2 svg-icon-md-1">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    
                    {/* Header Content */}
                    <div className="header shadow shadow-sm card-dashboardheader bg-white d-flex py-1 w-100 ps-4">
                      <div className="header-left mr-auto mx-2 d-flex flex-grow-1 py-2">
                        <div className="d-block">
                          <p className="my-auto fw-normal text-uppercase">{formattedDate}</p>
                          <h5 className="fw-bold">Welcome {user?.name || "CL User"}!</h5>
                        </div>
                      </div>
                      <div className="header-right">
                        <div className="app-navbar-item" id="kt_header_user_menu_toggle">
                          <div className="d-flex pt-1" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                            <div className="text-end d-none d-sm-flex flex-column justify-content-start py-2">
                              <div className="align-self-end lead">
                                <h6 className="fs-6 fw-bold font-custom">{user?.name || "CL User"}</h6>
                                <p className="fw-bold font-custom fs-7 text-dark">{user?.email}</p>
                              </div>
                            </div>
                            <div className="text-center">
                              <div className="symbol symbol-50px symbol-circle me-3" style={{ height: "10px" }}>
                                <img
                                  src={user?.picture ? `${IMAGE_URL}${user?.picture}` : ''}
                                  style={{ height: "35px", width: "35px" }}
                                  alt="User"
                                />
                              </div>
                              <div className="h-15px me-3 mt-4">
                                <Tooltip title="Notifications">
                                  <Link
                                    to='/notification'
                                    className='mx-2 h-15px d-inline-block position-relative'
                                    style={{ cursor: "pointer", textDecoration: "none" }}
                                    onClick={clearNotification}
                                  >
                                    <BsBell className="align-top text-dark"/>
                                    {badgeNo > 0 && (
                                      <span className="text-light position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                        {badgeNo}
                                      </span>
                                    )}
                                  </Link>
                                </Tooltip>
                                <Tooltip title="Settings">
                                  <Link
                                    to='/profiletest'
                                    className='mx-1 h-15px d-inline-block'
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsGear className="align-top text-dark" />
                                  </Link>
                                </Tooltip>
                                <Tooltip title="Logout">
                                  <a
                                    onClick={logout}
                                    className='mx-1 h-15px d-inline-block'
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsPower className="align-top text-dark" />
                                  </a>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {/* Sidebar and Content */}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                      <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"></div>
                    </div>
                  </div>
                </header>
                
                <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                  <aside id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle" style={{ backgroundColor: "#363740" }}>
                    <div className="app-sidebar-logo sidebar-bg border-1" id="kt_app_sidebar_logo" style={{ borderBottom: "#363740, solid" }}>
                      <div className="container text-center">
                        <img src={`${BASE_URL}/assets/images/CL-logo.png`} className="img-fluid app-sidebar-logo-default" style={{ height: "60px", width: "auto" }} alt="CL Logo" />
                        <img src={`${BASE_URL}/assets/images/CL-logo-small.png`} className="h-40px app-sidebar-logo-minimize img-fluid w-auto" style={{ height: "50px", width: "auto" }} alt="CL Logo Small" />
                      </div>
                      <button
                        id="kt_app_sidebar_toggle"
                        className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
                        data-kt-toggle="true"
                        data-kt-toggle-state="active"
                        data-kt-toggle-target="body"
                        data-kt-toggle-name="app-sidebar-minimize"
                        onClick={() => dispatch(setSidebarState(!sidebarOpened))}
                      >
                        <span className="svg-icon svg-icon-2 rotate-180 text-success">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
                            <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div className="app-sidebar-menu overflow-hidden fw-normal flex-column-fluid sidebar-bg">
                      <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper fw-normal hover-scroll-overlay-y d-flex flex-column">
                        <div className="menu menu-column fw-normal menu-rounded menu-sub-indention" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                          {renderMenuItems(menuItems)}
                        </div>
                        <div className={sidebarOpened ? "text-center mt-auto" : "px-7 mt-auto"}></div>
                      </div>
                    </div>
                    <div className="app-sidebar-logo sidebar-bg border-0" id="kt_app_sidebar_logo">
                      <div className="container text-center">
                        <img src={`${BASE_URL}/assets/images/Sysreformslogo2.png`} className="img-fluid app-sidebar-logo-default py-2" style={{ height: "50px", width: "auto" }} alt="Sysreforms Logo" />
                        <img src={`${BASE_URL}/assets/images/Sysreformssmlogo.png`} className="app-sidebar-logo-minimize w-auto" style={{ height: "40px", width: "auto", backgroundColor: "#06373A" }} alt="Sysreforms Small Logo" />
                      </div>
                    </div>
                  </aside>
                  
                  {/* Main Content */}
                  <div>
                    {children}
                    <div className="d-flex flex-column flex-column-fluid">
                      <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div id="kt_app_content_container" className="app-container container-fluid"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ThemeProvider>
        </div>
      )}
    </>
  );
};

export default BackendLayout;