import React, { useEffect, useState } from 'react'; 
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel,
  FormControl, Snackbar, Alert
} from '@mui/material';
import { api } from '../api';

const VehicleList = () => {
  const [companies, setCompanies] = useState([]);
  const [models, setModels] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    registration_no: '',
    company_id: '',
    model_id: '',
    build_year: 2010,
    available_seats: 0,
    image: null,
    color: '',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchCompanies();
    fetchVehicles(currentPage);
  }, [currentPage]);

  const fetchCompanies = async () => {
    try {
      const response = await api.get('/companies');
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchModels = async (companyId) => {
    try {
      const response = await api.get(`/models`, { params: { company_id: companyId, dropdown: 'true' } });
      setModels(response.data);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const fetchVehicles = async (page = 1) => {
    try {
      const response = await api.get('/vehicles', {
        params: { page }
      });
      setVehicles(response.data.data || []);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompanyId = event.target.value;
    setFormData({ ...formData, company_id: selectedCompanyId, model_id: '' });
    fetchModels(selectedCompanyId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
    }
  };

  const handleSubmit = async () => {
    const { registration_no, company_id, model_id, available_seats } = formData;

    let formErrors = {};
    if (!registration_no) formErrors.registration_no = 'Registration number is required';
    if (!company_id) formErrors.company_id = 'Company is required';
    if (!model_id) formErrors.model_id = 'Model is required';
    if (available_seats < 0) formErrors.available_seats = 'Available seats must be at least 0';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    try {
      const request = editingId
        ? api.put(`/vehicles/${editingId}`, formDataToSubmit)
        : api.post('/vehicles', formDataToSubmit);

      await request;
      fetchVehicles(currentPage);
      setFlashMessage({ message: editingId ? 'Vehicle updated successfully!' : 'Vehicle added successfully!', severity: 'success' });
      handleClose();
    } catch (error) {
      console.error('Error saving vehicle:', error);
      setFlashMessage({ message: 'Error saving vehicle. Please try again.', severity: 'error' });
    }
  };

  const handleOpen = (vehicle = null) => {
    if (vehicle) {
      setFormData({
        registration_no: vehicle.registration_no,
        company_id: vehicle.company_id,
        model_id: vehicle.model_id,
        build_year: vehicle.build_year,
        available_seats: vehicle.available_seats,
        image: null,
        color: vehicle.color,
      });
      setEditingId(vehicle.id);
    } else {
      setFormData({
        registration_no: '',
        company_id: '',
        model_id: '',
        build_year: 2010,
        available_seats: 0,
        image: null,
        color: '',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteVehicle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/vehicles/${id}`);
          fetchVehicles(currentPage);
          Swal.fire({
            title: 'Deleted!',
            text: 'The vehicle has been deleted.',
            icon: 'success',
            timer: 2700,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error deleting vehicle:', error);
          Swal.fire({
            title: 'Error!',
            text: 'There was an error deleting the vehicle. Please try again.',
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Vehicle List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Vehicle
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Id</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Registration No</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Build Year</TableCell>
              <TableCell>Available Seats</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicles.length > 0 ? (
              vehicles.map(vehicle => (
                <TableRow key={vehicle.id}>
                  <TableCell>{vehicle.user_id}</TableCell>
                  <TableCell>{vehicle.user_name}</TableCell>
                  <TableCell>{vehicle.registration_no}</TableCell>
                  <TableCell>{vehicle.company_name}</TableCell>
                  <TableCell>{vehicle.model_name}</TableCell>
                  <TableCell>{vehicle.build_year}</TableCell>
                  <TableCell>{vehicle.available_seats}</TableCell>
                  <TableCell>{vehicle.color}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleOpen(vehicle)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => deleteVehicle(vehicle.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">No vehicles available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span style={{ margin: '0 15px' }}>Page {currentPage} of {totalPages}</span>
        <Button
          variant="contained"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Vehicle' : 'Add Vehicle'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Company</InputLabel>
            <Select
              name="company_id"
              value={formData.company_id}
              onChange={handleCompanyChange}
              required
            >
              {companies.map(company => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
            {errors.company_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.company_id}</div>}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Model</InputLabel>
            <Select
              name="model_id"
              value={formData.model_id}
              onChange={handleChange}
              required
            >
              {models.map(model => (
                <MenuItem key={model.id} value={model.id}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
            {errors.model_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.model_id}</div>}
          </FormControl>
          <TextField
            label="Registration No"
            name="registration_no"
            variant="outlined"
            value={formData.registration_no}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.registration_no}
            helperText={errors.registration_no}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Build Year</InputLabel>
            <Select
              name="build_year"
              value={formData.build_year}
              onChange={handleChange}
              required
            >
              {Array.from({ length: 45 }, (v, i) => 1980 + i).map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Available Seats</InputLabel>
            <Select
              name="available_seats"
              value={formData.available_seats}
              onChange={handleChange}
              required
            >
              {Array.from({ length: 10 }, (v, i) => i).map(seat => (
                <MenuItem key={seat} value={seat}>{seat}</MenuItem>
              ))}
            </Select>
            {errors.available_seats && <div style={{ color: 'red', marginTop: '4px' }}>{errors.available_seats}</div>}
          </FormControl>
          <Button variant="contained" component="label" margin="normal">
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>

          <FormControl fullWidth margin="normal">
            <InputLabel>Color</InputLabel>
            <Select
              name="color"
              value={formData.color}
              onChange={handleChange}
            >
              <MenuItem value="">Select Color</MenuItem>
              <MenuItem value="White">White</MenuItem>
              <MenuItem value="Black">Black</MenuItem>
              <MenuItem value="Red">Red</MenuItem>
              <MenuItem value="Green">Green</MenuItem>
              <MenuItem value="Blue">Blue</MenuItem>
              <MenuItem value="Yellow">Yellow</MenuItem>
              <MenuItem value="Cyan">Cyan</MenuItem>
              <MenuItem value="Magenta">Magenta</MenuItem>
              <MenuItem value="Orange">Orange</MenuItem>
              <MenuItem value="Purple">Purple</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VehicleList;
