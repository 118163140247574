import React, { useState, useEffect, useRef } from "react";
import { API_URL, BASE_URL } from "../../../constants";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Container, Row, Modal } from "react-bootstrap";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Button } from "@mui/base";
import Stack from "@mui/material/Stack";
import { TextField, Tooltip } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { GoogleMap, Autocomplete, MarkerF } from "@react-google-maps/api";
import { Link, useNavigate } from "react-router-dom";
import { useSelector ,useDispatch} from "react-redux";
import { Checkbox, FormControlLabel } from "@mui/material";
import Swal from "sweetalert2";
import { displayNotification } from "../../../helpers";
// import moment from "moment/moment";
import { useLocation } from 'react-router-dom';
import { setContactIdState } from "../../../redux/generalSlice";

const eighteenYearsAgo = dayjs().subtract(18, "years");

const FinalRiderRegistration = () => {
  // const option0 = useSelector((s) => s.general.data.option0);
  // ////console.log(option0);
  const backgroundStyle = {
    backgroundImage: `url(${BASE_URL}/assets/images/CL-logo.png)`,
    // backgroundSize: 'cover',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100",
    height: "20vh",
    // Set the desired height of the background area
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const autocompleteRef = useRef(null);
  const userToken = useSelector((s) => s.login.data.token);
 
  const userId = useSelector((s) => s.general.data.userId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const contact_id_user = queryParams.get('contact');

  const [daysSelected, setDaysSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidProfession, setIsValidProfession] = useState(true);

  // For Registration
  const [validated, setValidated] = useState(false);
  const [homeTimeSlots, setHomeTimeSlots] = useState([]);
  const [selectedHomeTime, setSelectedHomeTime] = useState("");
  const [selectedHomeTimeValue, setSelectedHomeTimeValue] = useState("");

  const [preferredGender, setPreferredGender] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const selectedDateFormat = selectedDate
    ? selectedDate.format("DD-MM-YYYY")
    : "";
  const [martialStatus, setMartialStatus] = useState("");
  const [education, setEducation] = useState("");
  const [profession, setProfession] = useState("");
  const [cnic, setCnic] = useState("");
  const [isValidCnic, setIsValidCnic] = useState(true);
  const [cnicFront, setCnicFront] = useState(null);
  const [cnicFrontExt, setCnicFrontExt] = useState(null);
  const [cnicBack, setCnicBack] = useState(null);
  const [cnicBackExt, setCnicBackExt] = useState(null);
  const [picture, setPicture] = useState(null);
  const [pictureExt, setPictureExt] = useState(null);



 
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);





  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };



  // Function to handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    // Check if the day is already selected or not
    if (daysSelected.includes(value)) {
      // Day is already selected, remove it from the array
      setDaysSelected((prevSelectedDays) =>
        prevSelectedDays.filter((day) => day !== value)
      );
    } else {
      // Day is not selected, add it to the array
      setDaysSelected((prevSelectedDays) => [...prevSelectedDays, value]);
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const newDateObject = dayjs(newDate);
      setSelectedDate(newDateObject);
    }
  };

  const handleCnicFront = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setCnicFront(reader.result.split(",")[1]);
          setCnicFrontExt(file.name.split(".").pop());
        };
        reader.readAsDataURL(file);
      } else {
        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setCnicFront(null);
          setCnicFrontExt(null);
        }, 100);
      }
    }
  };

  

  const handleCnicBack = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setCnicBack(reader.result.split(",")[1]);
          setCnicBackExt(file.name.split(".").pop());
        };
        reader.readAsDataURL(file);
      } else {
        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setCnicBack(null);
          setCnicBackExt(null);
        }, 100);
      }
    }
  };

  
  const handlePicture = (e) => {
    const file = e.target.files[0];
    const maxSize = 5120000;
    if (file) {
      if (file.size <= maxSize) {
        const reader = new FileReader();
        reader.onload = () => {
          setPicture(reader.result.split(",")[1]);
          setPictureExt(file.name.split(".").pop());
        };
        reader.readAsDataURL(file);
      } else {
        displayNotification("warning", "Your file is exceeds 5MB");
        setTimeout(() => {
          e.target.value = null;
          setPicture(null);
          setPictureExt(null);
        }, 100);
      }
    }
  };




  const PersonalFormFields = [
   // martialStatus,
  //  cnic,
  //  selectedDateFormat,
  //  preferredGender,
  //  profession,
   // education,
  ];

  const requiredFields = [
    selectedHomeTime,
    daysSelected,
    martialStatus,
    cnic,
    selectedDateFormat,
    profession,
    education,
    cnicFrontExt,
    cnicFront,
    cnicBackExt,
    cnicBack,
    pictureExt,
    picture,
  ];


  const handleForm = async () => {
    await PersonalForm();

     await CompleteForm();
   
     /* if (
       requiredFields.every(
         (field) => field !== "" && field !== null && field !== undefined
       )
     ) {
       setIsLoading(true); 

       try {
         if (
           PersonalFormFields.every(
             (field) => field !== "" && field !== null && field !== undefined
           )
         ) {
           if (
             locationStartString === locationEndString ||
             markerPositionStart === markerPositionEnd
           ) {
             displayNotification(
               "warning",
               "Please select different starting and drop-off point"
            );
             setIsLoading(false);
           } else {
            
           }
         }
       } catch (error) {
         setIsLoading(false);
         // Handle the error appropriately, e.g., show an error message
         ////console.error("API call error:", error);
       }
     } else {
       setIsLoading(false);
       // Swal.fire({
       //   position: 'top',
       //   // icon: 'warning',
       //   text: 'Please Fill All Fields!',
       //   customClass: {
       //     confirmButton: 'swal-custom', // Apply custom CSS class to the OK button
       //   },
       // }
       // )
       displayNotification("warning", "Please Fill All Fields");
     } */

    //navigate('/dashboard');

  };

  const PersonalForm = async () => {
    try {
      const body = {
        marital_status: martialStatus,
        cnic: cnic,
        birth_year: selectedDateFormat,
        preferred_gender: preferredGender,
        profession: profession,
        education: education,
        interests: null,
        university_address: null,
        university_name: null,
        user_type: 299,
      };
      const response = await fetch(`${API_URL}/api/v1/registration/personal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(body),
      });


      const jsonresponse = await response.json();

      if (jsonresponse.statusCode == 200) {
     
       await ImagesFormCnicFront();
        await ImagesFormCnicBack();
        await ImagesFormPicture();

        setIsLoading(false);
      } else if (jsonresponse.statusCode === 422) {
        //////console.log("Personal Form CNIC Issue Response:", jsonresponse);
        const errors = jsonresponse.errors;
        for (const field of Object.keys(errors)) {
          displayNotification("error", `${errors[field][0]}`);
        }
        setIsLoading(false);
      }
    } catch (error) {
      //////console.log(error.message);
    }
  };

  const CompleteForm = async () => {
    try {
      const response = await fetch(
        `${API_URL}/api/v1/forms-completed/${userId}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const jsonresponse = await response.json();
      if (contact_id_user !== null && contact_id_user !== undefined) {
        dispatch(setContactIdState(contact_id_user));
          navigate("/commuter-profile");
        }
        else{
         navigate("/dashboard");
        }
    
     
    } catch (error) {
      //////console.error("An error occurred:", error);
     
    }
  };


  const ImagesFormCnicFront = async () => {
    try {
      const body = {
        cnic_front_image_ext: cnicFrontExt,
        cnic_front_image: cnicFront,
      };
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/cnic_front`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );

      ////////console.log("Images Form Body Cnic Front:", body);

      const jsonresponse = await response.json();

      if (jsonresponse.statusCode == 200) {
        //////console.log("Images Form Response Cnic Front:", jsonresponse);
      } else {
        // alert("Error: " + jsonresponse.message);
        // Swal.fire({
        //   position: 'top',
        //   // icon: 'error',
        //   text: `${jsonresponse.message}`,
        //   customClass: {
        //     confirmButton: 'swal-custom', // Apply custom CSS class to the OK button
        //   },
        // }
        // )
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      //////console.log(error.message);
    }
  };

  const ImagesFormCnicBack = async () => {
    try {
      const body = {
        cnic_back_image_ext: cnicBackExt,
        cnic_back_image: cnicBack,
      };
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/cnic_back`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );

     // ////console.log("Images Form Body Cnic Back:", body);

      const jsonresponse = await response.json();

      if (jsonresponse.statusCode == 200) {
        ////console.log("Images Form Response Cnic Back:", jsonresponse);
      } else {
       
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      ////console.log(error.message);
    }
  };

  const ImagesFormPicture = async () => {
    try {
      const body = {
        picture_image_ext: pictureExt,
        picture: picture,
      };
      const response = await fetch(
        `${API_URL}/api/v1/registration/store-images/picture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(body),
        }
      );

   

      const registrationSuccessful = () => {
       
        displayNotification(
          "success",
          "Rider Registration Form Submitted successfully"
        );
      };

      const jsonresponse = await response.json();

      if (jsonresponse.statusCode === 200) {
      
        registrationSuccessful();
       
      } else {
      
        displayNotification("error", `${jsonresponse.message}`);
      }
    } catch (error) {
      ////console.log(error.message);
    }
  };




  function validateProfession(profession) {
    // A simple regular expression to match alphabetic characters and spaces
    const professionPattern = /^[A-Za-z\s]+$/;

    return professionPattern.test(profession);
  }
  const handleProfessionChange = (e) => {
    const newProfession = e.target.value;
    setProfession(newProfession);
    setIsValidProfession(validateProfession(newProfession));
  };

  function validateCnic(cnic) {
    // Regular expression pattern for validating Pakistani CNIC (12345-1234567-1)
    const cnicPattern = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;

    return cnicPattern.test(cnic);
  }
  const handleCnicChange = (event) => {
    const inputCnic = event.target.value.replace(/\D/g, "");
    if (cnic.length < 15 && cnic.length > 15) {
      setIsValidCnic(false);
    }
    if (inputCnic.length <= 13) {
      const formattedCnic = inputCnic.replace(
        /^(\d{5})(\d{7})(\d{1})$/,
        "$1-$2-$3"
      );
      setCnic(formattedCnic);
      setIsValidCnic(validateCnic(formattedCnic));
    }
  };
  return (
    <>
      <div className="main-bg">
        <div className="containter p-5 position-relative">
          <div className="area">
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="row justify-content-center ">
            <div className="col-md-6 shadow bg-white  mt-5 mb-5">
              <div
                className="row shadow form-color-header"
              >
                <h1 className="text-center text-white py-4">
                  Registration Form
                </h1>
              </div>
              <Form
                className=" p-3"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
              

              <Row
                  className="mb-3 py-3 px-3 shadow shadow-sm form-color-field"
                   style={{ backgroundColor: " #cddbd9" }}
                >
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom15"
                    className="mb-2"
                  >
                    <Form.Label className="fs-6 text-black">
                      Date of Birth
                    </Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={"MM/DD/YY"}
                        className="bg-white"
                        style={{
                          scrollbarColor: "darkblue",
                        }}
                        slotProps={{
                          textField: { size: "small", color: "success" },
                        }}
                        sx={{ width: "100%" }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        maxDate={eighteenYearsAgo}
                        disableFuture
                      />
                    </LocalizationProvider>
                  </Form.Group>

             

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom16"
                    className="mb-2"
                  >
                    <Form.Label className="fs-6 text-black">
                      Marital Status
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="text-secondary"
                      value={martialStatus}
                      onChange={(e) => setMartialStatus(e.target.value)}
                      required
                    >
                      <option value="" hidden>
                        Marital Status
                      </option>
                      <option value="Married">Married</option>
                      <option value="Single">Single</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom17"
                    className="mb-2"
                  >
                    <Form.Label className="fs-6 text-black">
                      Education
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      className="text-secondary"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      required
                    >
                      <option value="" hidden>
                        Education
                      </option>
                      <option value="Phd">Phd</option>
                      <option value="Master">Master</option>
                      <option value="Bachelor">Bachelor</option>
                      <option value="BA">BA</option>
                      <option value="BSC">BSC</option>
                      <option value="FSC">Intermediate</option>
                      <option value="Matric">Matric</option>
                      <option value="Middle">Middle</option>
                      <option value="Primary">Primary</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom18"
                    className="mb-2"
                  >
                    <Form.Label className="fs-6 text-black">
                      Profession
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className={`${isValidProfession ? "" : "is-invalid"}`}
                      placeholder="Profession (Engineer, Doctor, etc)"
                      value={profession}
                      onChange={handleProfessionChange}
                    />
                    {!isValidProfession && (
                      <div className="invalid-feedback">
                        Please Enter a Valid Profession.
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Row
                  className="mb-3 py-3 px-3 shadow shadow-sm form-color-field"
                  // style={{ backgroundColor: " #cddbd9" }}
                >
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom19"
                    className="mb-2"
                  >
                    <Form.Label className="fs-6 text-black">CNIC</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      className={`${isValidCnic ? "" : "is-invalid"}`}
                      placeholder="1234512345671"
                      value={cnic}
                      onChange={handleCnicChange}
                    />
                    {!isValidCnic && (
                      <div className="invalid-feedback">
                        Please Enter a Valid CNIC without dashes in the Format
                        1234512345671.
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group
                    controlId="formFile"
                    as={Col}
                    md="12"
                    className="mb-2"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label className="fs-6 text-black">
                        {" "}
                        Upload CNIC (Front)
                      </Form.Label>
                      <p
                        className="colorplace text-danger"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                       
                      >
                        <Tooltip
                          title={
                            <h6 className="px-2">
                              {
                                "Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."
                              }
                            </h6>
                          }
                        >
                          <Link
                            // to='/notification'
                            className="mx-1 h-15px d-inline-block"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                          </Link>
                        </Tooltip>
                      </p>
                    </div>

                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg"
                      required
                      onChange={handleCnicFront}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formFile"
                    as={Col}
                    md="12"
                    className="mb-2"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label className="fs-6 text-black">
                        {" "}
                        Upload CNIC (Back)
                      </Form.Label>
                      <p
                        className="colorplace text-danger"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                       
                      >
                        <Tooltip
                          title={
                            <h6 className="px-2">
                              {
                                "Please submit a high-quality image of your CNIC in one of the specified formats: jpg, png, jpeg, or heic, ensuring that all the information is clearly legible. The file size should not exceed 5MB."
                              }
                            </h6>
                          }
                        >
                          <Link
                            // to='/notification'
                            className="mx-1 h-15px d-inline-block"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                          </Link>
                        </Tooltip>
                      </p>
                    </div>

                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg"
                      required
                      onChange={handleCnicBack}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formFile"
                    as={Col}
                    md="12"
                    className="mb-3"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label className="fs-6 text-black">
                        Upload Your Picture
                      </Form.Label>
                      <p
                        className="colorplace text-danger"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        
                      >
                        <Tooltip
                          title={
                            <h6 className="px-2">
                              {
                                "Please submit a high-quality image of your recent picture in one of the specified formats: jpg, png, jpeg, or heic, ensuring that your face is clearly visible. The file size should not exceed 5MB."
                              }
                            </h6>
                          }
                        >
                          <Link
                            // to='/notification'
                            className="mx-1 h-15px d-inline-block"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fs-4 fa-circle-info icon-tooltip-blue"></i>
                          </Link>
                        </Tooltip>
                      </p>
                    </div>

                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg"
                      required
                      onChange={handlePicture}
                    />
                   <Form.Text
                      className="text-danger"
                      style={{ color: "#000" }}
                    >
                      
                    </Form.Text> *
                  </Form.Group>
                </Row> 
                <div
                  className="alert alert-info alert-dismissible fade show"
                  role="alert"
                >
                  {" "}
                  <h6 className="text-left d-flex">
                    {" "}
                    <i className="fa-solid fa-lock fa-fw text-danger text-center pe-3"></i>
                    <li className="text-danger" style={{ listStyle: "none" }} >
                      {" "}
                      Your data is protected within a secured environment.
                    </li>
                  </h6>
                  <button
                    type=""
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  >
                    
                  </button>
                </div>

                <Stack
                  direction="row"
                  className="mb-4"
                  spacing={2}
                  style={{ justifyContent: "right" }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    className="btn-custom1 mx-2 border-0 px-4 py-2 rounded rounded-2 text-white fw-bold"
                    onClick={handleForm}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <i className="fa fa-spinner fa-spin" /> Submitting...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>

                  

           



              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalRiderRegistration;
