import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert,
  FormControl, Pagination
} from '@mui/material';
import { api } from '../api';

const DriverList = () => {
  const [drivers, setDrivers] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    driver_image: null,
    license_image: null,
    license_no: '',
    validity_date: '',
    years_of_experience: 0,
    is_smoker: false,
    dob: '',
    status: ''
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchDrivers = async (page = 1) => {
    try {
      const response = await api.get(`/drivers?page=${page}`);
      const formattedDrivers = response.data.map(driver => ({
        ...driver,
        is_smoker: driver.is_smoker === 1,
      }));
      setDrivers(formattedDrivers);
      // Set pagination if your API returns pagination details
      // setTotalPages(response.data.last_page);
      // setCurrentPage(response.data.current_page);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    fetchDrivers(currentPage);
  }, [currentPage]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [imageType]: file });
    }
  };

  const handleSubmit = async () => {
    const { name, phone, license_no, validity_date, years_of_experience, dob, status, is_smoker } = formData;

    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!phone) formErrors.phone = 'Phone number is required';
    if (!license_no) formErrors.license_no = 'License number is required';
    if (!validity_date) formErrors.validity_date = 'Validity date is required';
    if (years_of_experience < 0) formErrors.years_of_experience = 'Years of experience must be at least 0';
    if (!dob) formErrors.dob = 'Date of birth is required';
    if (!status) formErrors.status = 'Status is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, key === 'is_smoker' ? is_smoker.toString() : formData[key]);
    }

    try {
      const request = editingId
        ? api.put(`/drivers/${editingId}`, formDataToSubmit, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        : api.post('/drivers', formDataToSubmit, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

      await request;
      fetchDrivers(currentPage);
      setFlashMessage({ message: editingId ? 'Driver updated successfully!' : 'Driver added successfully!', severity: 'success' });
      handleClose();
    } catch (error) {
      console.error('Error saving driver:', error);
      setFlashMessage({ message: 'Error saving driver. Please try again.', severity: 'error' });
    }
  };

  const handleOpen = (driver = null) => {
    if (driver) {
      setFormData({
        name: driver.name,
        phone: driver.phone,
        driver_image: null,
        license_image: null,
        license_no: driver.license_no,
        validity_date: driver.validity_date,
        years_of_experience: driver.years_of_experience,
        is_smoker: driver.is_smoker,
        dob: driver.dob,
        status: driver.status,
      });
      setEditingId(driver.id);
    } else {
      setFormData({
        name: '',
        phone: '',
        driver_image: null,
        license_image: null,
        license_no: '',
        validity_date: '',
        years_of_experience: 0,
        is_smoker: false,
        dob: '',
        status: '',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteDriver = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/drivers/${id}`);
          fetchDrivers(currentPage);
          Swal.fire({
            title: 'Deleted!',
            text: 'The driver has been deleted.',
            icon: 'success',
            timer: 2700,
            showConfirmButton: false,
          });
        } catch (error) {
          console.error('Error deleting driver:', error);
          Swal.fire({
            title: 'Error!',
            text: 'There was an error deleting the driver. Please try again.',
            icon: 'error',
          });
        }
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Driver List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Driver
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>License No</TableCell>
              <TableCell>Validity Date</TableCell>
              <TableCell>Experience (Years)</TableCell>
              <TableCell>Is Smoker</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.length > 0 ? (
              drivers.map(driver => (
                <TableRow key={driver.id}>
                  <TableCell>{driver.name}</TableCell>
                  <TableCell>{driver.phone}</TableCell>
                  <TableCell>{driver.license_no}</TableCell>
                  <TableCell>{driver.validity_date}</TableCell>
                  <TableCell>{driver.years_of_experience}</TableCell>
                  <TableCell>{driver.is_smoker ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{driver.dob}</TableCell>
                  <TableCell>{driver.status}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleOpen(driver)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => deleteDriver(driver.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No drivers available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Driver' : 'Add Driver'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Phone"
            name="phone"
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <TextField
            label="License No"
            name="license_no"
            variant="outlined"
            value={formData.license_no}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.license_no}
            helperText={errors.license_no}
          />
          <TextField
            label="Validity Date"
            name="validity_date"
            type="date"
            variant="outlined"
            value={formData.validity_date}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.validity_date}
            helperText={errors.validity_date}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Years of Experience"
            name="years_of_experience"
            type="number"
            variant="outlined"
            value={formData.years_of_experience}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.years_of_experience}
            helperText={errors.years_of_experience}
          />
          <FormControl fullWidth margin="normal">
            <label>
              <input
                type="checkbox"
                name="is_smoker"
                checked={formData.is_smoker}
                onChange={handleChange}
              />
              Is Smoker
            </label>
          </FormControl>
          <TextField
            label="Date of Birth"
            name="dob"
            type="date"
            variant="outlined"
            value={formData.dob}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.dob}
            helperText={errors.dob}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Status"
            name="status"
            variant="outlined"
            value={formData.status}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.status}
            helperText={errors.status}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, 'driver_image')}
            style={{ margin: '10px 0' }}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, 'license_image')}
            style={{ margin: '10px 0' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!flashMessage.message} autoHideDuration={6000} onClose={() => setFlashMessage({ message: '', severity: '' })}>
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DriverList;
