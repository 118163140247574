import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputLabel,
  FormControl, Snackbar, Alert, Pagination, Grid, Select, MenuItem
} from '@mui/material'; // Added Select and MenuItem imports
import { api } from '../api';

const CountryList = () => {
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 50;

  useEffect(() => {
    fetchCountries();
  }, [page, searchTerm]);

  const fetchCountries = async () => {
    try {
      const response = await api.get('/countries', {
        params: {
          page,
          search: searchTerm,
        },
      });
      setCountries(response.data.data || []);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page on new search
    fetchCountries(); // Fetch countries after setting the new search term
  };

  const handleOpen = (country = null) => {
    if (country) {
      setFormData({
        name: country.name,
        status: country.status,
      });
      setEditingId(country.id);
    } else {
      setFormData({
        name: '',
        status: 'active',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name } = formData;

    // Basic validation
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/countries/${editingId}`, formData)
      : api.post('/countries', formData);

    request
      .then(() => {
        fetchCountries();
        setFlashMessage({ message: editingId ? 'Country updated successfully!' : 'Country added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving country:', error);
        setFlashMessage({ message: 'Error saving country. Please try again.', severity: 'error' });
      });
  };

  const deleteCountry = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/countries/${id}`)
          .then(() => {
            fetchCountries();
            Swal.fire({
              title: 'Deleted!',
              text: 'The country has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting country:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the country. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Country List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Country
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countries.map(country => (
              <TableRow key={country.id}>
                <TableCell>{country.name}</TableCell>
                <TableCell>{country.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(country)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteCountry(country.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Country' : 'Add Country'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CountryList;
