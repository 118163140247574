import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputLabel,
  FormControl, Snackbar, Alert, Pagination, Grid, Select, MenuItem
} from '@mui/material'; 
import { api } from '../api';

const ProvinceList = () => {
  const [provinces, setProvinces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    country_id: '',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchProvinces();
    fetchCountries();
  }, [page, searchTerm]);

  const fetchProvinces = async () => {
    try {
      const response = await api.get('/provinces', {
        params: {
          page,
          search: searchTerm,
        },
      });
      setProvinces(response.data.data || []);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await api.get('/countries', {
        params: {
          status: 'active', // Only get active countries
        },
      });
      setCountries(response.data.data || []);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handleOpen = (province = null) => {
    if (province) {
      setFormData({
        name: province.name,
        country_id: province.country_id,
        status: province.status,
      });
      setEditingId(province.id);
    } else {
      setFormData({
        name: '',
        country_id: '',
        status: 'active',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name, country_id } = formData;

    // Basic validation
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!country_id) formErrors.country_id = 'Country is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/provinces/${editingId}`, formData)
      : api.post('/provinces', formData);

    request
      .then(() => {
        fetchProvinces();
        setFlashMessage({ message: editingId ? 'Province updated successfully!' : 'Province added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving province:', error);
        setFlashMessage({ message: 'Error saving province. Please try again.', severity: 'error' });
      });
  };

  const deleteProvince = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/provinces/${id}`)
          .then(() => {
            fetchProvinces();
            Swal.fire({
              title: 'Deleted!',
              text: 'The province has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting province:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the province. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Province List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Province
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {provinces.map(province => (
              <TableRow key={province.id}>
                <TableCell>{province.name}</TableCell>
                <TableCell>{countries.find(country => country.id === province.country_id)?.name}</TableCell>
                <TableCell>{province.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(province)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteProvince(province.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Province' : 'Add Province'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Country</InputLabel>
            <Select
              name="country_id"
              value={formData.country_id}
              onChange={handleChange}
              required
            >
              {countries.map(country => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {errors.country_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.country_id}</div>}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProvinceList;
