import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, Box, Card, CardContent, Typography, Snackbar, Alert } from '@mui/material';
import { api } from '../api'; // Adjust the import based on your API setup

const RecommendationsList = () => {
  const [data, setData] = useState({});
  const [selectedSchedule, setSelectedSchedule] = useState('');
  const [scheduleKeys, setScheduleKeys] = useState([]);
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  const fetchRecommendations = async () => {
    try {
      const response = await api.get('/recommendations'); // Adjust the endpoint as needed
      setData(response.data);

      const keys = Object.keys(response.data);
      setScheduleKeys(keys);

      if (keys.length > 0) {
        setSelectedSchedule(keys[0]); // Select the first schedule by default
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setFlashMessage({ message: 'Error fetching recommendations. Please try again.', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchRecommendations();
  }, []);

  const handleScheduleChange = (event, newValue) => {
    setSelectedSchedule(newValue);
  };

  const renderEntities = () => {
    if (!selectedSchedule || !data[selectedSchedule]) return null;

    const drivers = data[selectedSchedule]?.original?.drivers;
    const riders = data[selectedSchedule]?.original?.riders;

    // Check for drivers
    if (drivers !== undefined) {
      if (drivers.length > 0) {
        return drivers.map((driver) => (
          <Card key={driver.rider_id} style={{ margin: '10px' }}>
            <CardContent>
              <Typography variant="h7">{driver.rider_id}</Typography>
              <Typography variant="h6">{driver.user_name}</Typography>
              <Typography variant="h6">Email: {driver.email_driver}</Typography>
              <Typography color="textSecondary">Travel Time: {driver.travel_time} mins</Typography>
              <Typography color="textSecondary">Car: {driver.car}</Typography>
            </CardContent>
          </Card>
        ));
      } else {
        return <Typography>No drivers found for this schedule.</Typography>;
      }
    }

    // Check for riders
    if (riders !== undefined) {
      if (riders.length > 0) {
        return riders.map((rider) => (
          <Card key={rider.rider_id} style={{ margin: '10px' }}>
            <CardContent>
              <Typography variant="h7">{rider.rider_id}</Typography>
              <Typography variant="h6">{rider.user_name}</Typography>
              <Typography variant="h6">Email: {rider.email_rider}</Typography>
              <Typography color="textSecondary">Travel Time: {rider.travel_time} mins</Typography>
            </CardContent>
          </Card>
        ));
      } else {
        return <Typography>No riders found for this schedule.</Typography>;
      }
    }

    return <Typography>No drivers or riders available for this schedule.</Typography>;
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Recommendations List</h1>

      <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
        <Tabs value={selectedSchedule} onChange={handleScheduleChange} textColor="secondary" indicatorColor="secondary">
          {scheduleKeys.map((key) => (
            <Tab key={key} value={key} label={key} />
          ))}
        </Tabs>
      </AppBar>

      <Box style={{ marginTop: '20px' }}>
        {renderEntities()}
      </Box>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RecommendationsList;
