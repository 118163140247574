import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material'; // Ensure all necessary components are imported
import { api } from '../api'; // Ensure your API instance includes authentication

const BankAccountList = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    bank_name: '',
    account_holder_name: '',
    account_number: '',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  // Assuming you have user authentication and can get the current user's ID
  const userId = 1; // Replace with actual user ID retrieval method

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const fetchBankAccounts = () => {
    api.get(`/bank-accounts?user_id=${userId}`)
      .then(response => setBankAccounts(response.data))
      .catch(error => console.error('Error fetching bank accounts:', error));
  };

  const handleOpen = (bankAccount = null) => {
    if (bankAccount) {
      setFormData({
        bank_name: bankAccount.bank_name,
        account_holder_name: bankAccount.account_holder_name,
        account_number: bankAccount.account_number,
        status: bankAccount.status,
      });
      setEditingId(bankAccount.id);
    } else {
      setFormData({ bank_name: '', account_holder_name: '', account_number: '', status: 'active' });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { bank_name, account_holder_name, account_number, status } = formData;

    // Basic validation
    let formErrors = {};
    if (!bank_name) formErrors.bank_name = 'Bank Name is required';
    if (!account_holder_name) formErrors.account_holder_name = 'Account Holder Name is required';
    if (!account_number) formErrors.account_number = 'Account Number is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/bank-accounts/${editingId}`, { bank_name, account_holder_name, account_number, status, user_id: userId })
      : api.post('/bank-accounts', { bank_name, account_holder_name, account_number, status, user_id: userId });

    request
      .then(() => {
        fetchBankAccounts();
        setFlashMessage({ message: editingId ? 'Bank Account updated successfully!' : 'Bank Account added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving bank account:', error);
        setFlashMessage({ message: 'Error saving bank account. Please try again.', severity: 'error' });
      });
  };

  const deleteBankAccount = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/bank-accounts/${id}?user_id=${userId}`)
          .then(() => {
            fetchBankAccounts();
            Swal.fire({
              title: 'Deleted!',
              text: 'The bank account has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting bank account:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the bank account. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Bank Account List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add New Bank Account
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>Account Holder</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankAccounts.map(bankAccount => (
              <TableRow key={bankAccount.id}>
                <TableCell>{bankAccount.account_number}</TableCell>
                <TableCell>{bankAccount.account_holder_name}</TableCell>
                <TableCell>{bankAccount.bank_name}</TableCell>
                <TableCell>{bankAccount.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(bankAccount)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteBankAccount(bankAccount.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Bank Account' : 'Add Bank Account'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Bank Name"
            name="bank_name"
            variant="outlined"
            value={formData.bank_name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.bank_name}
            helperText={errors.bank_name}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Account Holder Name"
            name="account_holder_name"
            variant="outlined"
            value={formData.account_holder_name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.account_holder_name}
            helperText={errors.account_holder_name}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Account Number"
            name="account_number"
            variant="outlined"
            value={formData.account_number}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.account_number}
            helperText={errors.account_number}
            style={{ marginBottom: '16px' }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
              required
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert
          onClose={() => setFlashMessage({ message: '', severity: '' })}
          severity={flashMessage.severity}
          sx={{ width: '100%' }}
          style={{
            backgroundColor: flashMessage.severity === 'error' ? '#f8d7da' : '#d4edda',
            color: flashMessage.severity === 'error' ? '#721c24' : '#155724'
          }}
        >
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BankAccountList;
