import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, TextField,
  InputLabel, FormControl, Snackbar, Alert, Select, MenuItem, IconButton,RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { api } from '../api';

const ScheduleForm = () => {
  const initializePoint = () => ({
    province_id: '',
    province_name: '',
    city_id: '',
    city_name: '',
    town_id: '',
    town_name: '',
    address: '',
    departureTime: '09:00',
    returnTime: '17:00',
  });

  


const handleSeatChange = (event) => {
  setFormData({ ...formData, seats: event.target.value });
};


  const [formData, setFormData] = useState({
    scheduleName: '',
    goingTo: '',
    driver_id: null,
    vehicle_id: null,
    family_member_id: null,

    seats: 1,

    rideType: 'looking', // New state for the radio buttons
    points: {
      starting: initializePoint(),
      ending: initializePoint(),
      middle_1: initializePoint(),
      middle_2: initializePoint(),
      middle: [],
      // Middle points will be added dynamically
    },

    // points: {
    //   starting: { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' },
    //   ending: { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' },
    //   middle: [],
    // },
  });
  const [familyMembers, setFamilyMembers] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [open, setOpen] = useState(false);
  const [currentPointType, setCurrentPointType] = useState('');
  const [currentMiddlePointIndex, setCurrentMiddlePointIndex] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetchFamilyMembers();
    fetchProvinces(1); // Assuming country_id = 1
    fetchDrivers();
    fetchVehicles();

  }, []);

  const fetchDrivers = async () => {
    try {
      const response = await api.get('/drivers', { params: { dropdown: true } });
      setDrivers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };
  
  const fetchVehicles = async () => {
    try {
      const response = await api.get('/vehicles', { params: { dropdown: true } });
      setVehicles(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };



  const fetchFamilyMembers = async () => {
    try {
      const response = await api.get('/family-members', { params: { dropdown: true } });
      setFamilyMembers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching family members:', error);
    }
  };

  const fetchProvinces = async (countryId) => {
    try {
      const response = await api.get('/provinces', { params: { dropdown: true, country_id: countryId } });
      setProvinces(response.data || []);
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await api.get('/cities', { params: { dropdown: true, province_id: provinceId } });
      setCities(response.data || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchTowns = async (cityId) => {
    try {
      const response = await api.get('/towns', { params: { dropdown: true, city_id: cityId } });
      setTowns(response.data || []);
    } catch (error) {
      console.error('Error fetching towns:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRideTypeChange = (value) => {
    const newSeats = value === 'looking' ? 1 : 3; // Default to 1 for looking, 3 for offering
    setFormData({ ...formData, rideType: value, seats: newSeats });
  };
  

  const handlePointChange = (point, field, value) => {
    setFormData(prev => ({
      ...prev,
      points: {
        ...prev.points,
        [point]: {
          ...prev.points[point],
          [field]: value,
        },
      },
    }));
  };

  const handleMiddlePointChange = (index, field, value) => {
    const newMiddlePoints = [...formData.points.middle];
    newMiddlePoints[index] = {
      ...newMiddlePoints[index],
      [field]: value,
    };
    setFormData({ ...formData, points: { ...formData.points, middle: newMiddlePoints } });
  };

  const addMiddlePoint = () => {
    setFormData(prev => ({
      ...prev,
      points: {
        ...prev.points,
        middle: [...prev.points.middle, { province_id: '', province_name: '', city_id: '', city_name: '', town_id: '', town_name: '', address: '', departureTime: '09:00', returnTime: '17:00' }],
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const { points, ...rest } = formData;
      const dataToPost = {
        ...rest,
        starting_point: points.starting,
        ending_point: points.ending,
        middle_points: points.middle,
        middle_1: points.middle_1,
        middle_2: points.middle_2,

      };

      await api.post('/schedules', dataToPost); // POST request to the schedules API
      setFlashMessage({ message: 'Schedule created successfully!', severity: 'success' });
      clearFormData();
      setOpen(false);
    } catch (error) {
      console.error('Error creating schedule:', error);
      setFlashMessage({ message: 'Failed to create schedule.', severity: 'error' });
    }
  };

  const clearFormData = () => {
    setFormData({
      scheduleName: '',
      goingTo: '',
      driver_id: null,
      vehicle_id: null,
      family_member_id: null,


      rideType: 'looking',
      points: {
        starting: initializePoint(),
        ending: initializePoint(),
        middle_1: initializePoint(),
        middle_2: initializePoint(),
        middle: [],
        // Middle points will be added dynamically
      },
    });
    setCities([]);
    setTowns([]);
  };

  const openPointModal = (pointType, index) => {
    setCurrentPointType(pointType);
    setCurrentMiddlePointIndex(index);
    if (pointType === 'middle' && index !== null) {
      const selectedMiddlePoint = formData.points.middle[index];
      if (selectedMiddlePoint) {
        fetchCities(selectedMiddlePoint.province_id);
        fetchTowns(selectedMiddlePoint.city_id);
      }
    }
  };

  const closePointModal = () => {
    if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
      const newMiddlePoints = [...formData.points.middle];
      newMiddlePoints[currentMiddlePointIndex] = {
        province_id: '',
        province_name: '',
        city_id: '',
        city_name: '',
        town_id: '',
        town_name: '',
        address: '',
        departureTime: '09:00',
        returnTime: '17:00',
      };
      setFormData(prev => ({
        ...prev,
        points: { ...prev.points, middle: newMiddlePoints },
      }));
    }
    setCurrentPointType('');
    setCurrentMiddlePointIndex(null);
  };

  const handleDeletePoint = (pointType) => {
    setFormData(prev => {
      const updatedPoints = { ...prev.points };
  
      if (pointType === 'starting') {
        updatedPoints.starting = initializePoint();
      } else if (pointType === 'ending') {
        updatedPoints.ending = initializePoint();
      } else if (pointType === 'middle_1') {
        updatedPoints.middle_1 = initializePoint();
      } else if (pointType === 'middle_2') {
        updatedPoints.middle_2 = initializePoint();
      }
  
      return { ...prev, points: updatedPoints };
    });
  
    closePointModal();
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Create New Schedule
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          Create Schedule
          <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={() => { setOpen(false); clearFormData(); }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Schedule Name"
            name="scheduleName"
            variant="outlined"
            value={formData.scheduleName}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />

        <RadioGroup
          row
          name="rideType"
          value={formData.rideType}
          onChange={(e) => handleRideTypeChange(e.target.value)}
        >
          <FormControlLabel value="looking" control={<Radio />} label="Looking for car" />
          <FormControlLabel value="offering" control={<Radio />} label="Offering a car" />
        </RadioGroup>

          <FormControl fullWidth margin="normal">
        <InputLabel>{formData.rideType === 'looking' ? 'Seats Required' : 'Seats Offered'}</InputLabel>
        <Select
          value={formData.seats}
          onChange={handleSeatChange}
        >
          {(formData.rideType === 'looking' ? [1, 2, 3] : Array.from({ length: 10 }, (_, i) => i + 1)).map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
      <InputLabel>Choose Driver</InputLabel>
<Select
  name="driver_id"
  value={formData.driver_id || ''}
  onChange={(e) => setFormData({ ...formData, driver_id: e.target.value })}
>
  {drivers.map(driver => (
    <MenuItem key={driver.id} value={driver.id}>{driver.name}</MenuItem>
  ))}
</Select>

</FormControl>

<FormControl fullWidth margin="normal">
<InputLabel>Choose Vehicle</InputLabel>
<Select
  name="vehicle_id"
  value={formData.vehicle_id || ''}
  onChange={(e) => setFormData({ ...formData, vehicle_id: e.target.value })}
>
  {vehicles.map(vehicle => (
    <MenuItem key={vehicle.id} value={vehicle.id}>{vehicle.name}</MenuItem>
  ))}
</Select>
</FormControl>







          <FormControl fullWidth margin="normal">
            <InputLabel id="going-to-label">Going to</InputLabel>
            <Select
              labelId="going-to-label"
              name="goingTo"
              value={formData.goingTo || ''}
              onChange={handleChange}
              required
            >
              <MenuItem value="school">School</MenuItem>
              <MenuItem value="office">Office</MenuItem>
              <MenuItem value="university">University</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
          <InputLabel>Schedule for</InputLabel>
            <Select
              name="family_member_id"
              value={formData.family_member_id === null ? 'self' : formData.family_member_id || ''}
              onChange={(e) => {
                const value = e.target.value === 'self' ? null : e.target.value;
                setFormData({ ...formData, family_member_id: value });
              }}
            >
              <MenuItem value='self'>My Self</MenuItem>
              {familyMembers.map(member => (
                <MenuItem key={member.id} value={member.id}>{member.name}</MenuItem>
              ))}
            </Select>
        </FormControl>

          <h4>Starting Point:</h4>
          {!formData.points.starting.province_id && (
            <Button onClick={() => openPointModal('starting', null)} variant="outlined">
              Add Starting Point
            </Button>
           )}

          {formData.points.starting.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.starting.province_name}, {formData.points.starting.city_name}, {formData.points.starting.town_name}
              <br />
              {formData.points.starting.address} - {formData.points.starting.departureTime} - {formData.points.starting.returnTime}
              <Button onClick={() => handleDeletePoint('starting')} style={{ float: 'right', color: 'red' }}>X</Button>
            </div>
          )}

          <h4>Ending Point:</h4>
          {!formData.points.ending.province_id && (
            <Button onClick={() => openPointModal('ending', null)} variant="outlined">
              Add Ending Point
            </Button>
          )}
          {formData.points.ending.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.ending.province_name}, {formData.points.ending.city_name}, {formData.points.ending.town_name}
              <br />
              {formData.points.ending.address} - {formData.points.ending.departureTime} - {formData.points.ending.returnTime}
              <Button onClick={() => handleDeletePoint('ending')} style={{ float: 'right', color: 'red' }}>X</Button>
            </div>
          )}

<h4>Middle Point 1:</h4>
          {!formData.points.middle_1.province_id && (
            <Button onClick={() => openPointModal('middle_1', null)} variant="outlined">
              Add Ending Point
            </Button>
          )}
          {formData.points.middle_1.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.middle_1.province_name}, {formData.points.middle_1.city_name}, {formData.points.middle_1.town_name}
              <br />
              {formData.points.middle_1.address} - {formData.points.middle_1.departureTime} - {formData.points.middle_1.returnTime}
              <Button onClick={() => handleDeletePoint('middle_1')} style={{ float: 'right', color: 'red' }}>X</Button>
            </div>
          )}

<h4>Middle Point 2:</h4>
          {!formData.points.middle_2.province_id && (
            <Button onClick={() => openPointModal('middle_2', null)} variant="outlined">
              Add Ending Point
            </Button>
          )}
          {formData.points.middle_2.province_id && (
            <div style={{ margin: '10px 0' }}>
              {formData.points.middle_2.province_name}, {formData.points.middle_2.city_name}, {formData.points.middle_2.town_name}
              <br />
              {formData.points.middle_2.address} - {formData.points.middle_2.departureTime} - {formData.points.middle_2.returnTime}
              <Button onClick={() => handleDeletePoint('middle_2')} style={{ float: 'right', color: 'red' }}>X</Button>
            </div>
          )}



          <h4>Middle Points:</h4>
          <Button onClick={addMiddlePoint} variant="outlined">Add Middle Point</Button>
          {formData.points.middle.map((point, index) => (
            <div key={index} style={{ margin: '10px 0' }}>
              <Button onClick={() => openPointModal('middle', index)} variant="outlined">
                {point.province_id ? 'Edit Middle Point' : 'Add Middle Point'}
              </Button>
              {point.province_id && (
                <div>
                  {point.province_name}, {point.city_name}, {point.town_name}
                  <br />
                  {point.address} - {point.departureTime} - {point.returnTime}
                  <Button onClick={() => handleDeletePoint('middle')} style={{ float: 'right', color: 'red' }}>X</Button>
                </div>
              )}
            </div>
          ))}

          <Dialog open={currentPointType !== ''} onClose={closePointModal}>
            <DialogTitle>
              {currentPointType.charAt(0).toUpperCase() + currentPointType.slice(1)} Point
              <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={closePointModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Province</InputLabel>
                <Select
                  value={
                    currentPointType === 'middle' && currentMiddlePointIndex !== null
                      ? formData.points.middle[currentMiddlePointIndex]?.province_id || ''
                      : formData.points[currentPointType]?.province_id || ''
                  }
                  onChange={(e) => {
                    const provinceId = e.target.value;
                    const provinceName = provinces.find(prov => prov.id === provinceId)?.name || '';
                    if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                      handleMiddlePointChange(currentMiddlePointIndex, 'province_id', provinceId);
                      handleMiddlePointChange(currentMiddlePointIndex, 'province_name', provinceName);
                      fetchCities(provinceId);
                    } else {
                      handlePointChange(currentPointType, 'province_id', provinceId);
                      handlePointChange(currentPointType, 'province_name', provinceName);
                      fetchCities(provinceId);
                    }
                  }}
                >
                  {provinces.map(province => (
                    <MenuItem key={province.id} value={province.id}>{province.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>City</InputLabel>
                <Select
                  value={
                    currentPointType === 'middle' && currentMiddlePointIndex !== null
                      ? formData.points.middle[currentMiddlePointIndex]?.city_id || ''
                      : formData.points[currentPointType]?.city_id || ''
                  }
                  onChange={(e) => {
                    const cityId = e.target.value;
                    const cityName = cities.find(city => city.id === cityId)?.name || '';
                    if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                      handleMiddlePointChange(currentMiddlePointIndex, 'city_id', cityId);
                      handleMiddlePointChange(currentMiddlePointIndex, 'city_name', cityName);
                      fetchTowns(cityId);
                    } else {
                      handlePointChange(currentPointType, 'city_id', cityId);
                      handlePointChange(currentPointType, 'city_name', cityName);
                      fetchTowns(cityId);
                    }
                  }}
                >
                  {cities.map(city => (
                    <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Town</InputLabel>
                <Select
                  value={
                    currentPointType === 'middle' && currentMiddlePointIndex !== null
                      ? formData.points.middle[currentMiddlePointIndex]?.town_id || ''
                      : formData.points[currentPointType]?.town_id || ''
                  }
                  onChange={(e) => {
                    const townId = e.target.value;
                    const townName = towns.find(town => town.id === townId)?.name || '';
                    if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                      handleMiddlePointChange(currentMiddlePointIndex, 'town_id', townId);
                      handleMiddlePointChange(currentMiddlePointIndex, 'town_name', townName);
                    } else {
                      handlePointChange(currentPointType, 'town_id', townId);
                      handlePointChange(currentPointType, 'town_name', townName);
                    }
                  }}
                >
                  {towns.map(town => (
                    <MenuItem key={town.id} value={town.id}>{town.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Street Address / House Number"
                variant="outlined"
                value={
                  currentPointType === 'middle' && currentMiddlePointIndex !== null
                    ? formData.points.middle[currentMiddlePointIndex]?.address || ''
                    : formData.points[currentPointType]?.address || ''
                }
                onChange={(e) => {
                  const address = e.target.value;
                  if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                    handleMiddlePointChange(currentMiddlePointIndex, 'address', address);
                  } else {
                    handlePointChange(currentPointType, 'address', address);
                  }
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Departure Time"
                type="time"
                value={
                  currentPointType === 'middle' && currentMiddlePointIndex !== null
                    ? formData.points.middle[currentMiddlePointIndex]?.departureTime || ''
                    : formData.points[currentPointType]?.departureTime || ''
                }
                onChange={(e) => {
                  const time = e.target.value;
                  if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                    handleMiddlePointChange(currentMiddlePointIndex, 'departureTime', time);
                  } else {
                    handlePointChange(currentPointType, 'departureTime', time);
                  }
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Return Time"
                type="time"
                value={
                  currentPointType === 'middle' && currentMiddlePointIndex !== null
                    ? formData.points.middle[currentMiddlePointIndex]?.returnTime || ''
                    : formData.points[currentPointType]?.returnTime || ''
                }
                onChange={(e) => {
                  const time = e.target.value;
                  if (currentPointType === 'middle' && currentMiddlePointIndex !== null) {
                    handleMiddlePointChange(currentMiddlePointIndex, 'returnTime', time);
                  } else {
                    handlePointChange(currentPointType, 'returnTime', time);
                  }
                }}
                fullWidth
                margin="normal"
              />
                      <Button variant="contained" color="primary" onClick={closePointModal} style={{ float: 'right', marginTop: '20px' }}>
            Save Point
          </Button>

            </DialogContent>
          </Dialog>

          <Button variant="contained" color="primary" onClick={handleSubmit} style={{ float: 'right', marginTop: '20px' }}>
            Save Schedule
          </Button>

          <Snackbar
            open={!!flashMessage.message}
            autoHideDuration={6000}
            onClose={() => setFlashMessage({ message: '', severity: '' })}
          >
            <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
              {flashMessage.message}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScheduleForm;
