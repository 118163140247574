import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputLabel,
  FormControl, Snackbar, Alert, Pagination, Grid, Select, MenuItem
} from '@mui/material'; 
import { api } from '../api';

const TownList = () => {
  const [towns, setTowns] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    country_id: '',
    province_id: '',
    city_id: '',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchCountries();
    fetchTowns();
  }, [page, searchTerm]);

  const fetchCountries = async () => {
    try {
      const response = await api.get('/countries', { params: { dropdown: true } });
      setCountries(response.data || []);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchTowns = async () => {
    try {
      const response = await api.get('/towns', {
        params: {
          page,
          search: searchTerm,
        },
      });
      setTowns(response.data.data || []);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching towns:', error);
    }
  };

  const fetchProvinces = async (countryId) => {
    try {
      const response = await api.get('/provinces', {
        params: {
          dropdown: true,
          country_id: countryId,
        },
      });
      setProvinces(response.data || []);
    } catch (error) {
      console.error('Error fetching provinces:', error);
    }
  };

  const fetchCities = async (provinceId) => {
    try {
      const response = await api.get('/cities', {
        params: {
          dropdown: true,
          province_id: provinceId,
        },
      });
      setCities(response.data || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value;
    setFormData({ ...formData, country_id: selectedCountryId, province_id: '', city_id: '' });
    fetchProvinces(selectedCountryId);
  };

  const handleProvinceChange = (event) => {
    const selectedProvinceId = event.target.value;
    setFormData({ ...formData, province_id: selectedProvinceId, city_id: '' });
    fetchCities(selectedProvinceId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handleOpen = (town = null) => {
    if (town) {
      setFormData({
        name: town.name,
        country_id: town.country_id,
        province_id: town.province_id,
        city_id: town.city_id,
        status: town.status,
      });
      setEditingId(town.id);
      fetchProvinces(town.country_id); // Load provinces for editing
      fetchCities(town.province_id); // Load cities for editing
    } else {
      setFormData({
        name: '',
        country_id: '',
        province_id: '',
        city_id: '',
        status: 'active',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name, country_id, province_id, city_id } = formData;

    // Basic validation
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!country_id) formErrors.country_id = 'Country is required';
    if (!province_id) formErrors.province_id = 'Province is required';
    if (!city_id) formErrors.city_id = 'City is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/towns/${editingId}`, formData)
      : api.post('/towns', formData);

    request
      .then(() => {
        fetchTowns();
        setFlashMessage({ message: editingId ? 'Town updated successfully!' : 'Town added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving town:', error);
        setFlashMessage({ message: 'Error saving town. Please try again.', severity: 'error' });
      });
  };

  const deleteTown = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/towns/${id}`)
          .then(() => {
            fetchTowns();
            Swal.fire({
              title: 'Deleted!',
              text: 'The town has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting town:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the town. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Town List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Town
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Province</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {towns.map(town => (
              <TableRow key={town.id}>
                <TableCell>{town.name}</TableCell>
                <TableCell>{town.city.name}</TableCell>
                <TableCell>{town.city.province.name}</TableCell>
                <TableCell>{town.city.province.country.name}</TableCell>
                <TableCell>{town.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(town)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteTown(town.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Town' : 'Add Town'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Country</InputLabel>
            <Select
              name="country_id"
              value={formData.country_id}
              onChange={handleCountryChange}
              required
            >
              {countries.map(country => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {errors.country_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.country_id}</div>}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Province</InputLabel>
            <Select
              name="province_id"
              value={formData.province_id}
              onChange={handleProvinceChange}
              required
            >
              {provinces.map(province => (
                <MenuItem key={province.id} value={province.id}>
                  {province.name}
                </MenuItem>
              ))}
            </Select>
            {errors.province_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.province_id}</div>}
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>City</InputLabel>
            <Select
              name="city_id"
              value={formData.city_id}
              onChange={handleChange}
              required
            >
              {cities.map(city => (
                <MenuItem key={city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
            {errors.city_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.city_id}</div>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editingId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!flashMessage.message} autoHideDuration={6000} onClose={() => setFlashMessage({ message: '', severity: '' })}>
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TownList;
