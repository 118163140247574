import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Snackbar, Alert
} from '@mui/material'; // Ensure all necessary components are imported
import { api } from '../api'; // Ensure your API instance includes authentication

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  // Assuming you have user authentication and can get the current user's ID
  const userId = 1; // Replace with actual user ID retrieval method

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = () => {
    api.get(`/transactions?user_id=${userId}`)
      .then(response => setTransactions(response.data))
      .catch(error => {
        console.error('Error fetching transactions:', error);
        setFlashMessage({ message: 'Error fetching transactions. Please try again.', severity: 'error' });
      });
  };

  // Function to determine the row color based on transaction type
  const getRowColor = (type) => {
    switch (type) {
      case 'deposit':
        return '#d4edda'; // Light green for deposits (funds in)
      case 'withdrawal':
      case 'transfer_out':
        return '#f8d7da'; // Light red for withdrawals and transfers out (funds out)
      default:
        return '#ffffff'; // Default white
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Transaction List</h1>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Related User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(transaction => (
              <TableRow
                key={transaction.id}
                style={{ backgroundColor: getRowColor(transaction.type) }} // Apply row color
              >
                <TableCell>{new Date(transaction.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{transaction.type}</TableCell>
                <TableCell>{transaction.amount}</TableCell>
                <TableCell>{transaction.related_user ? transaction.related_user.name : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert
          onClose={() => setFlashMessage({ message: '', severity: '' })}
          severity={flashMessage.severity}
          sx={{ width: '100%' }}
          style={{
            backgroundColor: flashMessage.severity === 'error' ? '#f8d7da' : '#d4edda',
            color: flashMessage.severity === 'error' ? '#721c24' : '#155724'
          }}
        >
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Transactions;
