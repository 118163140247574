import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { api } from '../api';

const FuelPriceCard = ({ fuelType, prices }) => {
  const [currentPrice, setCurrentPrice] = useState(null);
  const [lastPrice, setLastPrice] = useState(null);
  const [priceDifference, setPriceDifference] = useState('0');

  useEffect(() => {
    if (prices.length > 1) {
      const latest = parseFloat(prices[0]);
      const previous = parseFloat(prices[1]);
      setCurrentPrice(latest);
      setLastPrice(previous);
      const difference = latest - previous;
      setPriceDifference(difference !== 0 ? (difference > 0 ? `+${difference.toFixed(2)}` : difference.toFixed(2)) : '0');
    }
  }, [prices]);

  const formattedFuelType = fuelType
    .replace(/_(driver|rider)/g, '') // Remove "_driver" or "_rider"
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, char => char.toUpperCase()); // Title case
  const isCurrency = ['petrol', 'diesel', 'electricity'].includes(fuelType); // Check if it's a currency type

  return (
    <Card variant="outlined" style={{ margin: '20px', width: '200px' }}>
      <CardContent>
        <Typography 
          variant="h5" 
          component="div" 
          style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '4px', textAlign: 'center' }}
        >
          {formattedFuelType}
        </Typography>
        <Box sx={{ 
          marginTop: '10px', 
          textAlign: 'right', 
          fontSize: '1.2em', 
          color: priceDifference.startsWith('+') ? 'green' : (priceDifference.startsWith('-') ? 'red' : 'black') 
        }}>
          {priceDifference}
        </Box>
        <Typography variant="h4" component="div" style={{ fontWeight: 'bold', margin: '10px 0', textAlign: 'center' }}>
          <span style={{ fontSize: '0.9em' }}>{isCurrency ? 'Rs.' : '%'}</span>
          {currentPrice !== null ? `${currentPrice.toFixed(2)}` : '...'}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center' }}>
          Last Price: <span style={{ fontSize: '0.9em' }}>{isCurrency ? 'Rs.' : '%'}</span> 
          {lastPrice !== null ? `${lastPrice.toFixed(2)}` : '...'}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CurrentPrices = () => {
  const fuelTypes = [
    'petrol', 
    'diesel', 
    'electricity', 
    'commission_driver', 
    'commission_rider', 
    'gst_driver', 
    'gst_rider', 
    'other_driver', 
    'other_rider'
  ];

  const [fuelPrices, setFuelPrices] = useState({});

  useEffect(() => {
    const fetchFuelPrices = async () => {
      try {
        const response = await api.get('http://127.0.0.1:8000/api/latest-pricing-values?history=2');
        setFuelPrices(response.data);
      } catch (error) {
        console.error('Error fetching fuel prices:', error);
      }
    };

    fetchFuelPrices();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" component="h2" style={{ textAlign: 'center', marginBottom: '20px' }}>
        Pricing
      </Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {fuelTypes.filter(type => !type.includes('driver') && !type.includes('rider')).map(type => (
          <FuelPriceCard key={type} fuelType={type} prices={fuelPrices[type] || []} />
        ))}
      </div>

      <Typography variant="h4" component="h2" style={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>
        Drivers
      </Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {fuelTypes.filter(type => type.includes('driver')).map(type => (
          <FuelPriceCard key={type} fuelType={type} prices={fuelPrices[type] || []} />
        ))}
      </div>

      <Typography variant="h4" component="h2" style={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>
        Riders
      </Typography>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {fuelTypes.filter(type => type.includes('rider')).map(type => (
          <FuelPriceCard key={type} fuelType={type} prices={fuelPrices[type] || []} />
        ))}
      </div>
    </div>
  );
};

export default CurrentPrices;
