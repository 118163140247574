import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel,
  FormControl, Snackbar, Alert
} from '@mui/material';
import { api } from '../api';

const PricingValuesList = () => {
  const [pricingValues, setPricingValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    type: '',
    price: '',
    validationDate: '',
    effectiveDate: new Date().toISOString().split('T')[0], // Default to today's date
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });

  useEffect(() => {
    fetchPricingValues();
  }, []);

  const fetchPricingValues = () => {
    api.get('/pricing-values')
      .then(response => setPricingValues(response.data))
      .catch(error => console.error('Error fetching pricing values:', error));
  };

  const handleOpen = (pricingValue = null) => {
    if (pricingValue) {
      setFormData({
        type: pricingValue.type,
        price: pricingValue.value,
        validationDate: pricingValue.validation_date || '',
        effectiveDate: pricingValue.effective_date || new Date().toISOString().split('T')[0],
      });
      setEditingId(pricingValue.id);
    } else {
      setFormData({
        type: '',
        price: '',
        validationDate: '',
        effectiveDate: new Date().toISOString().split('T')[0],
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { type, price, validationDate, effectiveDate } = formData;

    // Basic validation
    let formErrors = {};
    if (!type) formErrors.type = 'Type is required';
    if (!price) formErrors.price = 'Price is required';
    // if (!validationDate) formErrors.validationDate = 'Valid Upto Date is required';
    if (!effectiveDate) formErrors.effectiveDate = 'Effective From date is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/pricing-values/${editingId}`, { type, value: price, validation_date: validationDate, effective_date: effectiveDate })
      : api.post('/pricing-values', { type, value: price, validation_date: validationDate, effective_date: effectiveDate });

    request
      .then(() => {
        fetchPricingValues();
        setFlashMessage({ message: editingId ? 'Pricing value updated successfully!' : 'Pricing value added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving pricing value:', error);
        setFlashMessage({ message: 'Error saving pricing value. Please try again.', severity: 'error' });
      });
  };

  const deletePricingValue = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/pricing-values/${id}`)
          .then(() => {
            fetchPricingValues();
            Swal.fire({
              title: 'Deleted!',
              text: 'The pricing value has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting pricing value:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the pricing value. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Pricing Values List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add New Pricing Value
      </Button>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Effective From</TableCell>
              <TableCell>Valid Upto</TableCell>
              {/* <TableCell>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {pricingValues.map(value => (
              <TableRow key={value.id}>
                 <TableCell>
      {value.type
        .replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ') // Split the text into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' ')} 
    </TableCell>
                <TableCell>{value.value}</TableCell>
                <TableCell>{value.effective_date}</TableCell>
                <TableCell>{value.validation_date}</TableCell>
                {/* <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(value)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deletePricingValue(value.id)}>
                    Delete
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Pricing Value' : 'Add Pricing Value'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
              label="Type"
              required
            >
              <MenuItem value="petrol">Petrol</MenuItem>
              <MenuItem value="diesel">Diesel</MenuItem>
              <MenuItem value="electricity">Electricity</MenuItem>
              <MenuItem value="commission_driver">Commission Driver</MenuItem>
              <MenuItem value="commission_rider">Commission Rider</MenuItem>
              <MenuItem value="gst_driver">GST Driver</MenuItem>
              <MenuItem value="gst_rider">GST Rider</MenuItem>
              <MenuItem value="other_driver">Other Driver</MenuItem>
              <MenuItem value="other_rider">Other Rider</MenuItem>
            </Select>
            {errors.type && <div style={{ color: 'red' }}>{errors.type}</div>}
          </FormControl>
          <TextField
            label="Price"
            name="price"
            variant="outlined"
            value={formData.price}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            label="Valid Upto Date"
            name="validationDate"
            type="date"
            variant="outlined"
            value={formData.validationDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.validationDate}
            helperText={errors.validationDate}
          />
          <TextField
            label="Effective From Date"
            name="effectiveDate"
            type="date"
            variant="outlined"
            value={formData.effectiveDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.effectiveDate}
            helperText={errors.effectiveDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingId ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert
          onClose={() => setFlashMessage({ message: '', severity: '' })}
          severity={flashMessage.severity}
          sx={{ width: '100%' }}
          style={{
            backgroundColor: flashMessage.severity === 'error' ? '#f8d7da' : '#d4edda',
            color: flashMessage.severity === 'error' ? '#721c24' : '#155724'
          }}
        >
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PricingValuesList;
