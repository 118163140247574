import { createSlice } from "@reduxjs/toolkit";

// Define the initial state with a clear structure
const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  provider: "",
  otp: "",
  token: "",
  googleToken: "",
  type: ""
};

// Create a slice of the Redux store for signup-related state
const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    // Action to set the signup state
    setSignupState: (state, action) => {
      // Update the state with the payload
      return { ...state, ...action.payload };
    },
    // Action to reset the signup state
    resetSignupState: () => initialState, // Reset to the initial state
  },
});

// Export the action creators for use in components
export const { setSignupState, resetSignupState } = signupSlice.actions;

// Export the reducer function to be used in the Redux store
export default signupSlice.reducer;
