import React, { useEffect, useState } from 'react'; 
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel,
  FormControl, Snackbar, Alert, Pagination, Grid
} from '@mui/material';
import { api } from '../api';

const ModelList = () => {
  const [companies, setCompanies] = useState([]);
  const [models, setModels] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    company_id: '',
    fuel_type: 'Petrol',
    engine_cc: '',
    seating_capacity: 5,
    mileage_city: 10,
    mileage_highway: 10,
    no_of_airbags: 2,
    transmission: 'manual',
    status: 'active',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [selectedCompany, setSelectedCompany] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 50;

  useEffect(() => {
    fetchCompanies();
    fetchModels();
  }, [selectedCompany, page, searchTerm]);

  const fetchCompanies = async () => {
    try {
      const response = await api.get('/companies');
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchModels = async () => {
    try {
      const response = await api.get('/models', { 
        params: { 
          company_id: selectedCompany, 
          page, 
          search: searchTerm 
        } 
      });
      setModels(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to first page on new search
  };

  const handleOpen = (model = null) => {
    if (model) {
      setFormData({
        name: model.name,
        company_id: model.company_id,
        fuel_type: model.fuel_type,
        engine_cc: model.engine_cc,
        seating_capacity: model.seating_capacity,
        mileage_city: model.mileage_city,
        mileage_highway: model.mileage_highway,
        no_of_airbags: model.no_of_airbags,
        transmission: model.transmission,
        status: model.status,
      });
      setEditingId(model.id);
    } else {
      setFormData({
        name: '',
        company_id: '',
        fuel_type: 'Petrol',
        engine_cc: '',
        seating_capacity: 5,
        mileage_city: 10,
        mileage_highway: 10,
        no_of_airbags: 2,
        transmission: 'manual',
        status: 'active',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name, company_id } = formData;

    // Basic validation
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!company_id) formErrors.company_id = 'Company is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const request = editingId
      ? api.put(`/models/${editingId}`, formData)
      : api.post('/models', formData);

    request
      .then(() => {
        fetchModels();
        setFlashMessage({ message: editingId ? 'Model updated successfully!' : 'Model added successfully!', severity: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error saving model:', error);
        setFlashMessage({ message: 'Error saving model. Please try again.', severity: 'error' });
      });
  };

  const deleteModel = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/models/${id}`)
          .then(() => {
            fetchModels();
            Swal.fire({
              title: 'Deleted!',
              text: 'The model has been deleted.',
              icon: 'success',
              timer: 2700,
              showConfirmButton: false,
            });
          })
          .catch(error => {
            console.error('Error deleting model:', error);
            Swal.fire({
              title: 'Error!',
              text: 'There was an error deleting the model. Please try again.',
              icon: 'error',
            });
          });
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Model List</h1>
      <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Model
      </Button>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Company</InputLabel>
            <Select value={selectedCompany} onChange={handleCompanyChange}>
              <MenuItem value="">
                <em>All Companies</em>
              </MenuItem>
              {companies.map(company => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
        </Grid>

        
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Fuel Type</TableCell>
              <TableCell>Engine CC</TableCell>
              <TableCell>Seating Capacity</TableCell>
              <TableCell>City Mileage</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map(model => (
              <TableRow key={model.id}>
                <TableCell>{model.name}</TableCell>
                <TableCell>{companies.find(c => c.id === model.company_id)?.name}</TableCell>
                <TableCell>{model.fuel_type}</TableCell>
                <TableCell>{model.engine_cc}</TableCell>
                <TableCell>{model.seating_capacity}</TableCell>
                <TableCell>{model.mileage_city}</TableCell>
                <TableCell>{model.status}</TableCell>
                <TableCell>
                  <Button variant="contained" color="secondary" onClick={() => handleOpen(model)}>
                    Edit
                  </Button>
                  <Button variant="outlined" color="error" onClick={() => deleteModel(model.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(event, value) => setPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Model' : 'Add Model'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
            style={{ marginBottom: '16px' }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Company</InputLabel>
            <Select
              name="company_id"
              value={formData.company_id}
              onChange={handleChange}
              label="Company"
              required
            >
              {companies.map(company => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
            {errors.company_id && <div style={{ color: 'red', marginTop: '4px' }}>{errors.company_id}</div>}
          </FormControl>
          <TextField
            label="Engine CC"
            name="engine_cc"
            variant="outlined"
            value={formData.engine_cc}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Seating Capacity</InputLabel>
            <Select
              name="seating_capacity"
              value={formData.seating_capacity}
              onChange={handleChange}
            >
              {[...Array(9).keys()].map(i => (
                <MenuItem key={i + 2} value={i + 2}>
                  {i + 2}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="City Mileage"
            name="mileage_city"
            type="number"
            variant="outlined"
            value={formData.mileage_city}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Highway Mileage"
            name="mileage_highway"
            type="number"
            variant="outlined"
            value={formData.mileage_highway}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Number of Airbags</InputLabel>
            <Select
              name="no_of_airbags"
              value={formData.no_of_airbags}
              onChange={handleChange}
            >
              {[...Array(11).keys()].map(i => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Fuel Type</InputLabel>
            <Select
              name="fuel_type"
              value={formData.fuel_type}
              onChange={handleChange}
            >
              <MenuItem value="Petrol">Petrol</MenuItem>
              <MenuItem value="Diesel">Diesel</MenuItem>
              <MenuItem value="Electric">Electric</MenuItem>
              <MenuItem value="Hybrid">Hybrid</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ModelList;
