import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Snackbar, Alert,
  FormControl, InputLabel, Select, MenuItem, Pagination
} from '@mui/material';
import { api } from '../api';

const FamilyMemberList = () => {
  const [familyMembers, setFamilyMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    img: null,
    relation: '',
    dob: '',
  });
  const [editingId, setEditingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [flashMessage, setFlashMessage] = useState({ message: '', severity: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const relationOptions = ['son', 'daughter', 'husband', 'wife', 'father', 'mother', 'other_male', 'other_female'];

  const fetchFamilyMembers = async (page = 1) => {
    try {
      const response = await api.get(`/family-members?page=${page}`);
      setFamilyMembers(response.data.data);
      setTotalPages(response.data.last_page);
      setCurrentPage(response.data.current_page);
    } catch (error) {
      console.error('Error fetching family members:', error);
    }
  };

  useEffect(() => {
    fetchFamilyMembers(currentPage);
  }, [currentPage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, img: file });
    }
  };

  const handleSubmit = async () => {
    const { name, dob, relation } = formData;
  
    let formErrors = {};
    if (!name) formErrors.name = 'Name is required';
    if (!dob) formErrors.dob = 'Date of birth is required';
    if (!relation) formErrors.relation = 'Relation is required';
  
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
  
    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
  
    // Append the appropriate method based on editing status
    if (editingId) {
      formDataToSubmit.append('_method', 'PUT');
    } else {
      formDataToSubmit.append('_method', 'POST');
    }
  
    try {
      // Use api.post for both creating and updating
      const request = editingId
        ? api.post(`/family-members/${editingId}`, formDataToSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        : api.post('/family-members', formDataToSubmit, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
      await request;
      fetchFamilyMembers(currentPage); // Fetch family members for the current page
      setFlashMessage({ message: editingId ? 'Family member updated successfully!' : 'Family member added successfully!', severity: 'success' });
      handleClose();
    } catch (error) {
      console.error('Error saving family member:', error.response.data);
      setFlashMessage({ message: 'Error saving family member. Please try again.', severity: 'error' });
    }
  };
  
  

  const handleOpen = (member = null) => {
    if (member) {
      setFormData({
        name: member.name,
        phone: member.phone || '', // Default to empty string if phone is not provided
        img: null,
        relation: member.relation,
        dob: member.dob,
      });
      setEditingId(member.id);
    } else {
      setFormData({
        name: '',
        phone: '',
        img: null,
        relation: '',
        dob: '',
      });
      setEditingId(null);
    }
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteFamilyMember = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/family-members/${id}`);
          fetchFamilyMembers(currentPage);
          Swal.fire('Deleted!', 'The family member has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting family member:', error);
          Swal.fire('Error!', 'There was an error deleting the family member. Please try again.', 'error');
        }
      }
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1>Family Members</h1>
      <Button  variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '20px' }}>
        Add New Family Member
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Relation</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {familyMembers.length > 0 ? (
              familyMembers.map(member => (
                <TableRow key={member.id}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>{member.phone || 'N/A'}</TableCell>
                  <TableCell>{member.relation}</TableCell>
                  <TableCell>{member.dob}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="secondary" onClick={() => handleOpen(member)}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => deleteFamilyMember(member.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">No family members available.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingId ? 'Edit Family Member' : 'Add Family Member'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            label="Phone (Optional)"
            name="phone"
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal" required error={!!errors.relation}>
            <InputLabel>Relation</InputLabel>
            <Select
              name="relation"
              value={formData.relation}
              onChange={handleChange}
            >
              {relationOptions.map((relation) => (
                <MenuItem key={relation} value={relation}>{relation}</MenuItem>
              ))}
            </Select>
            {errors.relation && <div style={{ color: 'red' }}>{errors.relation}</div>}
          </FormControl>
          <TextField
            label="Date of Birth"
            name="dob"
            type="date"
            variant="outlined"
            value={formData.dob}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            error={!!errors.dob}
            helperText={errors.dob}
          />
          <Button variant="contained" component="label" margin="normal">
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!flashMessage.message}
        autoHideDuration={6000}
        onClose={() => setFlashMessage({ message: '', severity: '' })}
      >
        <Alert onClose={() => setFlashMessage({ message: '', severity: '' })} severity={flashMessage.severity}>
          {flashMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FamilyMemberList;
