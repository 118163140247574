// loginSlice.js

import { createSlice } from "@reduxjs/toolkit";

// Initial state with default values
const initialState = {
  userToken: "",
  user: null,
  userRole: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      // Safeguards against undefined properties
      if (action.payload) {
        state.userToken = action.payload.userToken !== undefined ? action.payload.userToken : state.userToken;
        state.user = action.payload.user !== undefined ? action.payload.user : state.user;
        state.userRole =  action.payload.role !== undefined ? action.payload.userRole : state.userRole;
      } else {
        console.warn('Received undefined payload in setLoginState');
      }
    },
    resetLoginState: () => initialState,
  },
});

// Exporting actions for use in components
export const { setLoginState, resetLoginState } = loginSlice.actions;

// Exporting the reducer to be used in the store
export default loginSlice.reducer;
